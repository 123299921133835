import React, { useEffect, useLayoutEffect } from 'react';

import cx from 'classnames';
import { periodDisplayLanguage, useAuth, useDashboard, useOrganization, useSocket } from 'containers';
import { AlternativeSpinner, Heading, Spinner } from 'components';
import { RouteComponentProps } from 'react-router-dom';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Renew32 } from '@carbon/icons-react';

type LogoutProps = any;

export const Logout: React.FC<LogoutProps> = ({ dispatch }: { dispatch: any }) => {
  const { logout } = useAuth();

  useEffect(() => {
    function logoutAndRedirect() {
      const results = logout();

      if (results.ok !== false) {
        dispatch(Map({ type: 'SET_URL', url: '/' }));
      } else {
        console.error('Error logging out', results.error);
      }
    }

    logoutAndRedirect();
  }, []);

  return (
    <div
      className={classNames(
        'flex fixed top-0 left-0 h-100 w-100 z-999 bg-grid justify-center items-center flex-column',
      )}
    >
      <div className="flex flex-column items-start justify-start pa4 bg-white w6 ba bw2 b--navy">
        <img alt="easyrca logo" src="/static/media/logo-v2.02dda04013fba22260417aa65f03e3fe.svg" className="db mb4" />

        <div className={classNames('w-100 flex justify-start items-center fadeIn')}>
          <Spinner size={20} className="mr2" />
          <div className={'f5 fw-medium'}>Logging Out</div>
        </div>
      </div>
    </div>
  );
};
