import { FC, useEffect, useState } from 'react';
import { getG2ReviewToken } from '../Sync';
import G2Review from '../svgs/g2-review.svg';

type PlatformReviewCTAProps = {
  token: string;
  username: string;
};

export const PlatformReviewCTA: FC<PlatformReviewCTAProps> = ({ token, username }) => {
  const [g2State, setG2State] = useState(null);

  useEffect(() => {
    if (!token || !username || g2State) return;

    async function fetchG2Token() {
      try {
        const response = await getG2ReviewToken({ token });

        setG2State(response.state);
      } catch (error) {}
    }

    fetchG2Token();
  }, [token, g2State]);

  if (!token || !username || !g2State) return null;

  https: return (
    <a
      className="fadeIn no-underline"
      target="_blank"
      rel="noreferrer nofollow"
      href={`https://www.g2.com/partnerships/EasyRCA/users/login.embed?state=${g2State}&email=${username}`}
      style={{ marginRight: 20, marginTop: 3 }}
    >
      <img style={{ height: 26 }} src={G2Review} alt="Review us on G2" />
    </a>
  );
};
