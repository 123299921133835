import React from 'react';
import cx from 'classnames';
import { mean } from 'lodash';
import { animated, useSpring, config } from '@react-spring/web';

import { Divider, Sparkline } from 'components';
import { TaskTypes } from '@interfaces';
import { useDashboardRouting } from 'containers';
import styles from './styles.module.css';

type TaskStatusBreakdown = {
  lt2Weeks: number;
  gt2Weeks: number;
};

type TasksSnapshotProps = {
  correctiveActionsWeeklyAvg?: number[];
  verificationsWeeklyAvg?: number[];
  totalCorrectiveActions?: number;
  totalVerifications?: number;
  overdueCorrectiveActions?: TaskStatusBreakdown;
  overdueVerifications?: TaskStatusBreakdown;
  upcomingCorrectiveActions?: TaskStatusBreakdown;
  upcomingVerifications?: TaskStatusBreakdown;
};

type SnapshotFooterProps = {
  type: TaskTypes;
  // tasks that are marked as overdue, broken out by less than and greater than two weeks
  overdue?: TaskStatusBreakdown;
  // tasks that are due soon, broken out by less than and greater than two weeks
  upcoming?: TaskStatusBreakdown;
};

const SnapshotFooter = ({ type, overdue, upcoming }: SnapshotFooterProps) => {
  const [animatedStyles, set] = useSpring(() => ({
    from: { opacity: 0, bottom: -8 },
    config: {
      duration: 250,
    },
  }));

  React.useEffect(() => {
    set({
      from: { bottom: -8, opacity: 0 },
      to: { opacity: 1, bottom: 0 },
    });
  }, [set, overdue, upcoming]);

  const { openCorrectiveActionsFlyout, openVerificationsFlyout } = useDashboardRouting();

  const handleOpenFlyout = React.useCallback(
    ({ lt2Weeks, overdue }: { lt2Weeks: boolean; overdue: boolean }) => {
      const payload = {
        lt2Weeks: !!lt2Weeks,
        gt2Weeks: lt2Weeks !== true,
        overdue,
        upcoming: !overdue,
      };

      if (type === 'CORRECTIVE_ACTION') {
        return openCorrectiveActionsFlyout(payload);
      }

      return openVerificationsFlyout(payload);
    },
    [type, openVerificationsFlyout, openCorrectiveActionsFlyout],
  );

  return (
    <div className="flex flex-column overflow-hidden">
      <div className="flex items-stretch">
        <div
          className={cx('flex pv3 flex-column items-start w-50', styles['cardHorizontalPadding'])}
          style={{ borderRight: 'dashed 1px #ccc' }}
        >
          <span className="i18n fs-xxs fw-medium">Overdue</span>
          <div className="mt1 flex items-stretch justify-center w-100">
            <button
              // disabled={overdue?.gt2Weeks < 1}
              onClick={() => {
                if (overdue?.gt2Weeks && overdue?.gt2Weeks > 0) {
                  return handleOpenFlyout({
                    lt2Weeks: false,
                    overdue: true,
                  });
                }

                return;
              }}
              className={'unstyled pa0 ta-left  flex flex-column items-start w-50'}
            >
              <animated.span
                style={animatedStyles}
                className={cx(
                  {
                    'underline underline--light': overdue?.gt2Weeks && overdue?.gt2Weeks > 0,
                  },
                  'pt2 w-100 mono fs-xxl fw-semibold relative',
                )}
              >
                {overdue?.gt2Weeks}
              </animated.span>
              <span className="i18n mt2 fs-xxs fg--black-60">2+ Weeks</span>
            </button>
            <button
              // disabled={overdue?.lt2Weeks < 1}
              onClick={() => {
                if (overdue?.lt2Weeks && overdue?.lt2Weeks > 0) {
                  handleOpenFlyout({ lt2Weeks: true, overdue: true });
                }

                return;
              }}
              className="unstyled pa0 ta-left flex flex-column items-start w-50"
            >
              <animated.span
                style={animatedStyles}
                className={cx(
                  {
                    'underline underline--light': overdue?.lt2Weeks && overdue?.lt2Weeks > 0,
                  },
                  'pt2 w-100 mono fs-xxl fw-semibold relative',
                )}
              >
                {overdue?.lt2Weeks}
              </animated.span>
              <span className="i18n mt2 fs-xxs fg--black-60">1&#8211;2 Weeks</span>
            </button>
          </div>
        </div>

        <div className={cx('flex flex-column items-start w-50 pv3', styles['cardHorizontalPadding'])}>
          <span className="i18n fs-xxs fw-medium">Upcoming</span>
          <div className="mt1 flex items-stretch justify-start w-100">
            <button
              // disabled={overdue?.lt2Weeks < 1}
              onClick={() => {
                if (upcoming?.lt2Weeks && upcoming?.lt2Weeks > 0) {
                  return handleOpenFlyout({ lt2Weeks: true, overdue: false });
                }

                return;
              }}
              className="unstyled pa0 ta-left flex flex-column items-start w-50"
            >
              <animated.span
                style={animatedStyles}
                className={cx(
                  {
                    'underline underline--light': upcoming?.lt2Weeks && upcoming?.lt2Weeks > 0,
                  },
                  'pt2 w-100  mono fs-xxl fw-semibold relative',
                )}
              >
                {upcoming?.lt2Weeks}
              </animated.span>
              <span className="i18n mt2 fs-xxs fg--black-60">1&#8211;2 Weeks</span>
            </button>
            <button
              // disabled={overdue?.gt2Weeks < 1}
              onClick={() => {
                if (upcoming?.gt2Weeks && upcoming?.gt2Weeks > 0) {
                  return handleOpenFlyout({ lt2Weeks: false, overdue: false });
                }

                return;
              }}
              className="unstyled pa0 ta-left flex flex-column items-start w-50"
            >
              <animated.span
                style={animatedStyles}
                className={cx(
                  {
                    'underline underline--light': upcoming?.gt2Weeks && upcoming?.gt2Weeks > 0,
                  },
                  'pt2 w-100 mono fs-xxl fw-semibold relative',
                )}
              >
                {upcoming?.gt2Weeks}
              </animated.span>
              <span className="i18n mt2 fs-xxs fg--black-60">2+ Weeks</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TasksSnapshot = ({
  upcomingCorrectiveActions,
  overdueCorrectiveActions,
  correctiveActionsWeeklyAvg,
  verificationsWeeklyAvg,
  totalCorrectiveActions,
  totalVerifications,
  overdueVerifications,
  upcomingVerifications,
}: TasksSnapshotProps) => {
  const [fadeIn, set] = useSpring(() => ({
    opacity: 0,
    config: { duration: 550, config: config.gentle },
  }));
  const sparklineData = React.useMemo(
    () => ({
      correctiveActions: correctiveActionsWeeklyAvg?.slice(
        correctiveActionsWeeklyAvg.length - 8,
        correctiveActionsWeeklyAvg.length,
      ),
      verifications: verificationsWeeklyAvg?.slice(verificationsWeeklyAvg.length - 8, verificationsWeeklyAvg.length),
    }),
    [verificationsWeeklyAvg, correctiveActionsWeeklyAvg],
  );
  const taskAverages = React.useMemo(() => {
    const getValueForMean = (data?: number[]) => {
      const value = mean(data);

      if (value > 0 && value < 0.5) {
        return '< 1';
      } else if (value > 0.5 && value < 1) {
        return 1;
      }

      return Math.round(value);
    };

    return {
      correctiveActions: getValueForMean(correctiveActionsWeeklyAvg),
      verifications: getValueForMean(verificationsWeeklyAvg),
    };
  }, [correctiveActionsWeeklyAvg, verificationsWeeklyAvg]);

  React.useEffect(() => {
    set({ opacity: 1, from: { opacity: 0 } });
  }, [
    set,
    upcomingCorrectiveActions,
    overdueCorrectiveActions,
    correctiveActionsWeeklyAvg,
    verificationsWeeklyAvg,
    totalCorrectiveActions,
    totalVerifications,
    overdueVerifications,
    upcomingVerifications,
  ]);

  return (
    <div className={cx(styles['tasksSnapshotCard'])}>
      <div className={cx(styles['dashedRightBorder'], 'flex flex-column w-50')}>
        <div className={cx(styles['cardHorizontalPadding'], styles['tasksSnapshotCardHeader'])}>
          <div className="flex flex-column">
            <span className="i18n ma0 mb1 pa0 fs-s fw-medium">Verifications</span>
            <animated.h3 style={fadeIn} className="i18n ma0 fs-xs fw-light">
              {totalVerifications} New
            </animated.h3>
          </div>
          {/*<button
            className={cx(styles.viewAllButton, 'unstyled fw-medium fs-xxs')}
            // onClick={onViewAllVerifications}
          >
            View All
          </button>*/}
        </div>
        <div
          className={cx(
            styles['tasksSnapshotContent'],
            styles['cardHorizontalPadding'],
            styles['cardVerticalPadding'],
            'flex justify-between items-stretch',
          )}
        >
          <div className="flex flex-column">
            <h4 className="i18n ma0 pb1 fs-xxs fw-medium fg--black-60">Avg. Completed Weekly</h4>
            <animated.span style={fadeIn} className="fs-xxl mono fw-semibold">
              {taskAverages.verifications}
            </animated.span>
          </div>
          <div className="flex items-end">
            <Sparkline
              data={sparklineData.verifications?.map(avg => ({
                pv: avg,
                amt: avg,
              }))}
            />
          </div>
        </div>
        {/* <div
          className={styles.horizontalSeparator}
          style={{ marginRight: 24 }}
        /> */}
        <Divider dashed className="w-100" />
        <SnapshotFooter type="VERIFICATION" upcoming={upcomingVerifications} overdue={overdueVerifications} />
      </div>
      <div className="flex flex-column w-50">
        <div className={cx(styles['tasksSnapshotCardHeader'], styles['cardHorizontalPadding'])}>
          <div className="flex flex-column">
            <span className="i18n ma0 mb1 pa0 fs-s fw-medium">Corrective Actions</span>
            <animated.h3 style={fadeIn} className="i18n ma0 fs-xs fw-light">
              {totalCorrectiveActions} New
            </animated.h3>
          </div>
          {/*<button
            className={cx(styles.viewAllButton, 'unstyled fw-medium fs-xxs')}
            // onClick={onViewAllCorrectiveActions}
          >
            View All
          </button>*/}
        </div>
        <div
          className={cx(
            styles['tasksSnapshotContent'],
            styles['cardHorizontalPadding'],
            'flex justify-between items-stretch',
          )}
        >
          <div className="flex flex-column">
            <h4 className="i18n ma0 pb1 fs-xxs fw-medium fg--black-60">Avg. Completed Weekly</h4>
            <animated.span style={fadeIn} className="fs-xxl mono fw-semibold">
              {taskAverages.correctiveActions}
            </animated.span>
          </div>
          <div className="flex items-end">
            <Sparkline
              data={sparklineData.correctiveActions?.map(avg => ({
                pv: avg,
                amt: avg,
              }))}
            />
          </div>
        </div>
        <Divider dashed className="w-100" />
        <SnapshotFooter
          type="CORRECTIVE_ACTION"
          upcoming={upcomingCorrectiveActions}
          overdue={overdueCorrectiveActions}
        />
      </div>
    </div>
  );
};
