import React from 'react';
import { Map, List } from 'immutable';
import { Node, Tree } from './Tree';
import { defaultStylesheet, styleNode, bgMap, styleEdge, bgMapAttachmentStyles } from './Styles';

import failureEventSrc from './svgs/node_failure_event.svg';
import machinerySrc from './svgs/node_machinery.svg';
import materialsSrc from './svgs/node_materials.svg';
import methodSrc from './svgs/node_method.svg';
import motherNatureSrc from './svgs/node_mother_nature.svg';
import measurementSrc from './svgs/node_measurement.svg';
import manpowerSrc from './svgs/node_manpower.svg';

import genericSrc from './svgs/node_generic.svg';
import correctiveActionSrc from './svgs/node_corrective_action.svg';

export const stylesheet = defaultStylesheet(
  List(
    [
      styleNode('node.failureEvent', bgMap(failureEventSrc)),
      styleNode('node.machinery', bgMap(machinerySrc)),
      styleNode('node.materials', bgMap(materialsSrc)),
      styleNode('node.method', bgMap(methodSrc)),
      styleNode('node.motherNature', bgMap(motherNatureSrc)),
      styleNode('node.measurement', bgMap(measurementSrc)),
      styleNode('node.manpower', bgMap(manpowerSrc)),
      styleNode('node.generic', bgMap(genericSrc)),
    ]
      .concat(bgMapAttachmentStyles('node.failureEvent', failureEventSrc))
      .concat(bgMapAttachmentStyles('node.machinery', failureEventSrc))
      .concat(bgMapAttachmentStyles('node.materials', materialsSrc))
      .concat(bgMapAttachmentStyles('node.method', methodSrc))
      .concat(bgMapAttachmentStyles('node.motherNature', motherNatureSrc))
      .concat(bgMapAttachmentStyles('node.measurement', measurementSrc))
      .concat(bgMapAttachmentStyles('node.manpower', manpowerSrc))
      .concat(bgMapAttachmentStyles('node.generic', genericSrc))
      .concat([
        styleEdge(
          'edge',
          Map({
            'taxi-direction': 'downward',
            'curve-style': 'taxi',
            'line-style': 'solid',
            'line-color': '#aaa',
          }),
        ),
      ]),
  ),
);

const FishboneNode = type => Node(Map({ id: type, type }));

const FailureEvent = () => FishboneNode('failureEvent');

const Machinery = () => FishboneNode('machinery');

const Materials = () => FishboneNode('materials');

const Method = () => FishboneNode('method');

const MotherNature = () => FishboneNode('motherNature');

const Measurement = () => FishboneNode('measurement');

const Manpower = () => FishboneNode('manpower');

const Generic = () => FishboneNode('generic');

const CorrectiveAction = () => FishboneNode('correctiveAction');

const defaultNodes = () =>
  FailureEvent().set('children', List([Machinery(), Materials(), Method(), MotherNature(), Measurement(), Manpower()]));

const layout = Map({
  name: 'dagre',
  fit: true,
  animate: false,
  padding: 100,
});

export const typeToChildNode = t => {
  switch (t) {
    case 'machinery':
      return Machinery();
    case 'materials':
      return Materials();
    case 'method':
      return Method();
    case 'motherNature':
      return MotherNature();
    case 'measurement':
      return Measurement();
    case 'manpower':
      return Manpower();
    case 'correctiveAction':
      return CorrectiveAction();
    default:
      return Generic();
  }
};

export const defaultState = () =>
  Map({
    elements: defaultNodes(),
    view: Map({ layout, stylesheet }),
  });

export const FishboneTree = ({ state, dispatch, isViewOnly, isCompleted, ...props }) => {
  return <Tree isCompleted={isCompleted} isViewOnly={isViewOnly} state={state} dispatch={dispatch} {...props} />;
};
