import React from 'react';
import BaseLightbox from 'react-spring-lightbox';

export const Lightbox = ({ images, isOpen, onPrev, onNext, onClose, currentIndex, style, ...props }) => {
  return (
    <BaseLightbox
      isOpen={isOpen}
      onPrev={onPrev}
      onNext={onNext}
      renderImageOverlay
      onClose={onClose}
      images={images}
      currentIndex={currentIndex}
      style={{ background: 'rgba(0, 0, 0, 0.85)', ...style }}
      {...props}
    />
  );
};

Lightbox.displayName = 'Lightbox';
