import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAuth } from 'containers';

import { UserRole } from '@interfaces';

export const RoleProtectedRoute: React.FC<RouteProps & { role: keyof typeof UserRole; debug?: boolean }> = ({
  children,
  debug,
  role,
  ...rest
}) => {
  const { roles } = useAuth();
  const hasRequiredRole = roles ? roles[role.toLocaleLowerCase()] !== false : false;

  useEffect(() => {
    if (!debug) return;

    console.groupCollapsed(`[RoleProtectedRoute]: ${rest.path} – DEBUG`);
    console.info('Required role:', role);
    console.info('Route allowed?', hasRequiredRole);
    console.info('User roles:', roles);
    console.groupEnd();
  }, [hasRequiredRole, rest.path, role, roles, debug]);

  return (
    <Route
      {...rest}
      render={() => {
        if (hasRequiredRole) {
          return children;
        }

        return <Redirect to="/" />;
      }}
    />
  );
};
