import React, { useLayoutEffect, useEffect, useState, useCallback } from 'react';
import { Map, List, Set, Range } from 'immutable';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Renew32,
  Misuse20,
  Checkmark20,
  Group20,
  Tree20,
  ListChecked20,
  WarningAltInverted20,
  OperationsRecord20,
  Chemistry20,
  Debug20,
  Help20,
  Flow20,
  Fish20,
  Number_524,
  ChevronDown20,
  ChevronLeft20,
  ChevronRight20,
  NextFilled20,
  PreviousFilled20,
  Reset20,
  TrashCan20,
  Archive20,
  Add24,
  OverflowMenuHorizontal24,
} from '@carbon/icons-react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Blade, Section } from './Blade';
import { Button } from './Buttons';
import { AutocompleteInput, Input } from './TextInputs';
import { Page } from './Page';
import { Avatar } from './Nav';
import { Member } from './EventInfoOptions';
import { debounce } from './Tree';
import { initDispatchedWS } from './Sync';

export const ppStatus = status =>
  Map({
    PENDING: 'Pending',
    IN_PROCESS: 'In Process',
    REVIEW: 'Review',
    COMPLETE: 'Complete',
    ARCHIVE: 'Archived',
  }).get(status);

const TreeListItem = ({ tree, dispatch, state }) => {
  const [model, setModel] = useState(Map());
  const treeUuid = tree.get('treeUuid');
  const title = tree.get('title');
  const startAt = tree.get('startAt');
  const expectedCompletedAt = tree.get('expectedCompletedAt');
  const methodology = tree.get('methodology');
  const isDeleted = tree.get('deletedAt');
  const status = tree.get('status');
  const description = tree.get('description') || 'No description.';
  const descriptionSnippet = description.length > 48 ? description.substring(0, 48) + '...' : description;
  const username = state.get('username');
  const isTemplate = tree.get('isTemplate');
  // const isFavorite = tree.get('isFavorite');
  const createdBy = tree.get('createdBy');
  const createdByName = tree.get('createdByName');

  const hover = model.get('hover');

  const closeDropdown = useCallback(() => {
    if (model.has('dropdown')) {
      setModel(model.delete('dropdown'));
    }
  }, [setModel, model]);

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, [closeDropdown]);

  const members = Set(state.getIn(['trees', treeUuid, 'members'], Set()));
  return (
    <Link to={`/tree/${treeUuid}`} key={`${treeUuid}`} className={'no-underline'}>
      <ReactTooltip />
      <div
        className={classNames('ph1 pv2 relative trans flex items-center justify-between bt bl br b--gray black', {
          'bg-washed-green': hover,
        })}
        onMouseEnter={() => setModel(m => m.set('hover', true))}
        onMouseLeave={() => setModel(m => m.delete('hover'))}
      >
        <div
          onClick={e => {
            dispatch(
              Map({
                type: 'UPDATE_TREE',
                tree: tree.update('isTemplate', t => !t),
              }),
            );
            e.stopPropagation();
            e.preventDefault();
          }}
          className={classNames('pa2 trans', {
            'o-0': !hover,
            'o-100 dim': hover || isTemplate,
          })}
        >
          {isTemplate ? (
            <Archive20 data-tip="Remove from Template Library" className="gold" />
          ) : (
            <Archive20 data-tip="Add to Template Library" />
          )}
        </div>
        <div className="pa2 gray">
          {methodology === 'PROACT' ? (
            <Flow20 data-tip="PROACT" />
          ) : methodology === 'FISHBONE' ? (
            <Fish20 data-tip="Fishbone" />
          ) : (
            // eslint-disable-next-line react/jsx-pascal-case
            <Number_524 data-tip="5 Whys" />
          )}
        </div>
        <div>
          <div className="fw6" style={{ width: '400px' }}>
            {title || 'Untitled Analysis'}
          </div>
          <div className="gray f6 mt1">{descriptionSnippet}</div>
          {createdBy ? (
            <div className="gray f6 mt1" data-tip={createdBy} data-type="light" data-delay-show="750">
              Analysis Owner: {createdByName}
            </div>
          ) : null}
          <div className="mt1" style={{ fontSize: '13px' }}>
            Start Date: {startAt ? `${format(startAt, 'MM/dd/yyyy', new Date())}` : 'N/A'}
          </div>
          <div className="mt1" style={{ fontSize: '13px' }}>
            Expected Completion Date:{' '}
            {expectedCompletedAt ? `${format(expectedCompletedAt, 'MM/dd/yyyy', new Date())}` : 'N/A'}
          </div>
        </div>
        <div
          className="flex items-center justify-between"
          style={{ width: '128px' }}
          onClick={e => e.stopPropagation()}
        >
          <div
            className={'pa2 dim flex items-center'}
            onClick={e => {
              dispatch(Map({ type: 'PREV_STATUS', treeUuid }));
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ChevronLeft20 data-tip="Move to Previous Status" />
          </div>
          <div className="f6 flex items-center tc" style={{ flex: 1 }}>
            {ppStatus(status)}
          </div>
          <div
            className={'dim flex items-center'}
            onClick={e => {
              dispatch(Map({ type: 'NEXT_STATUS', treeUuid }));
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ChevronRight20 data-tip="Move to Next Status" />
          </div>
        </div>
        <div className={'flex items-center flex-row-reverse'} style={{ flex: 1 }}>
          {members
            .filterNot(u => u === username)
            .take(2)
            .sort()
            .map(u => {
              return <Avatar state={state} dispatch={dispatch} key={u} username={u} height={36} />;
            })}
          {members.size > 3 ? (
            <div
              className={'mh1 flex items-center'}
              data-tip={'More users are shared on this tree.'}
              data-place={'bottom'}
              data-delay-show={250}
            >
              <OverflowMenuHorizontal24 />
            </div>
          ) : (
            <div />
          )}
        </div>
        <div>
          {isDeleted ? (
            <div
              className="ml1 pa2 dim flex items-center"
              data-tip="Restore"
              onClick={e => {
                dispatch(Map({ type: 'RESTORE_TREE', treeUuid }));
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Reset20 data-tip="Restore" />
            </div>
          ) : (
            <div
              className="ml1 pa2 dim flex items-center"
              onClick={e => {
                dispatch(Map({ type: 'DELETE_TREE', treeUuid }));
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <TrashCan20 data-tip="Move to Trash" className={'fill--red'} />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export const filterTypeToFilter = filterType => tree => {
  const status = tree.get('status');
  const isDeleted = tree.get('deletedAt');
  const isTemplate = tree.get('isTemplate');

  switch (filterType) {
    case 'ALL':
      return status !== 'ARCHIVE' && !isDeleted;
    case 'REVIEW':
      return status === 'REVIEW' && !isDeleted;
    case 'IN_PROCESS':
      return status === 'IN_PROCESS' && !isDeleted;
    case 'PENDING':
      return status === 'PENDING' && !isDeleted;
    case 'COMPLETE':
      return status === 'COMPLETE' && !isDeleted;
    case 'ARCHIVED':
      return status === 'ARCHIVE' && !isDeleted;
    case 'TEMPLATE':
      return !isDeleted && isTemplate;
    case 'DELETED':
      return isDeleted;
    default:
      return true;
  }
};

const TreeList = ({ state, dispatch, count, page, treesList, filterType }) => {
  const paginate = array => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page - 1) * count, page * count);
    // return array;
  };

  return (
    <div
      className={classNames('mt2 w-100', {
        bb: treesList && treesList.size > 0,
      })}
    >
      {treesList ? (
        paginate(treesList).map((t, index) => {
          return <TreeListItem key={`${t.get('treeUuid')}_${index}`} tree={t} state={state} dispatch={dispatch} />;
        })
      ) : (
        <div className={'w-100 flex items-center black'}>
          <div className="spin blue">
            <Renew32 />
          </div>
          <div className={'ml3 f3'}>Building your RCA index...</div>
        </div>
      )}
    </div>
  );
};

const SharingUser = ({ state, dispatch, member }) => {
  const treeUuid = state.get('selectedTreeUUID');

  return (
    <Member
      state={state}
      dispatch={dispatch}
      member={member}
      onClick={() =>
        dispatch(
          Map({
            type: 'ADD_MEMBER',
            treeUuid,
            member,
          }),
        )
      }
    />
  );
};

const Sharing = ({ state, dispatch }) => {
  const defaultState = Map();
  const [model, setModel] = useState(defaultState);
  const treeUuid = state.get('selectedTreeUUID');
  const tree = state.getIn(['trees', treeUuid], Map());
  const members = Set(tree.get('members', Set()));

  return (
    <Blade
      title="Share Tree"
      state={state}
      dispatch={dispatch}
      show={state.getIn(['tree', 'view', 'contextualDrawer']) === 'SHARING'}
      onClose={() => {
        setModel(defaultState);
        dispatch(Map({ type: 'CLEAR_SELECTED_TREE' }));
      }}
    >
      <Section title={'Add New Member'}>
        <div className={'mb2'}>
          <AutocompleteInput
            internalOnly
            onSelect={text => {
              setModel(model.set('text', ''));
              if (!members.has(text)) {
                dispatch(
                  Map({
                    type: 'ADD_MEMBER',
                    treeUuid,
                    member: text,
                  }),
                );
              }
            }}
            state={state}
            dispatch={dispatch}
            value={model.get('text', '')}
            onChange={e => setModel(model.set('text', e.target.value))}
            placeholder={'Email address'}
            inverse
          />
        </div>
      </Section>
      <Section title={'Members'}>
        {members.sort().map(e => (
          <SharingUser state={state} dispatch={dispatch} key={e} member={e} />
        ))}
      </Section>
    </Blade>
  );
};

const AnalysesFilter = ({ state, dispatch, filterType, text }) => {
  const isSelected = state.get('filterType') === filterType;
  const count = state.get('trees', Map()).filter(filterTypeToFilter(filterType)).size;

  return (
    <div
      onClick={() => dispatch(Map({ type: 'SET_ANALYSES_FILTER', filterType }))}
      className={classNames('trans dim pointer pa2', {
        'bb bw1 b--blue blue': isSelected,
        gray: !isSelected,
      })}
    >
      {`${text} (${count})`}
    </div>
  );
};

const AnalysesFilters = ({ state, dispatch }) => {
  return (
    <div className="flex items-center f6 bb b--gray">
      <AnalysesFilter state={state} dispatch={dispatch} filterType="ALL" text="All" />
      <AnalysesFilter state={state} dispatch={dispatch} filterType="PENDING" text="Pending" />
      <AnalysesFilter state={state} dispatch={dispatch} filterType="IN_PROCESS" text="In Process" />
      <AnalysesFilter state={state} dispatch={dispatch} filterType="REVIEW" text="Review" />
      <AnalysesFilter state={state} dispatch={dispatch} filterType="COMPLETE" text="Complete" />
      <AnalysesFilter state={state} dispatch={dispatch} filterType="ARCHIVED" text="Archive" />
      <AnalysesFilter state={state} dispatch={dispatch} filterType="DELETED" text="Trash" />
      <AnalysesFilter state={state} dispatch={dispatch} filterType="TEMPLATE" text="Templates" />
    </div>
  );
};

const getIcon = type => {
  switch (type) {
    case 'true':
      return <Checkmark20 />;
    case 'humanRoot':
      return <Group20 />;
    case 'systemic':
      return <Tree20 />;
    case 'correctiveAction':
      return <ListChecked20 />;
    case 'failureEvent':
      return <WarningAltInverted20 />;
    case 'failureMode':
      return <OperationsRecord20 />;
    case 'physical':
      return <Chemistry20 />;
    case 'contributingFactor':
      return <Debug20 />;
    case 'hypothesis':
    default:
      return <Help20 />;
  }
};

const NodeResult = ({ state, dispatch, result }) => {
  const item = result.get('item');
  const text = item.get('text');
  const type = item.get('type');
  const treeUuid = item.get('treeUuid');
  const title = state.getIn(['trees', treeUuid, 'title']);

  const [model, setModel] = useState(Map());

  return (
    <div
      className={classNames('flex items-center bt bl br ph2 h3 pointer', {
        'bg-washed-blue': model.get('hover'),
      })}
      onMouseEnter={() => setModel(m => m.set('hover', true))}
      onMouseLeave={() => setModel(m => m.set('hover', false))}
      onClick={() => {
        dispatch(Map({ type: 'OPEN_TREE', treeUuid }));
        dispatch(Map({ type: 'CLEAR_SEARCH' }));
      }}
    >
      <div className="flex items-center">
        <div data-tip={type}>{getIcon(type)}</div>
        <div className="ml2">
          <div className="fw7">{text}</div>
          <div>{title || 'Untitled Analysis'}</div>
        </div>
      </div>
    </div>
  );
};

const SearchResult = ({ state, dispatch, result }) => {
  const item = result.get('item');
  const treeUuid = item.get('treeUuid');
  const tree = state.getIn(['trees', treeUuid]);
  const type = item.get('type');

  return (
    <div>
      {type ? (
        <NodeResult state={state} dispatch={dispatch} result={result} />
      ) : !tree ? (
        <div />
      ) : (
        <TreeListItem state={state} dispatch={dispatch} tree={tree} />
      )}
    </div>
  );
};

const SearchResults = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map({ start: 0 }));
  const limit = 20;

  const allSearchResults = state.get('searchResults', List()).sortBy(x => x.get('score'));

  const nResults = allSearchResults.size;

  const searchResults = allSearchResults.skip(model.get('start')).take(limit);
  const start = model.get('start');

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div>
      <div className="bb">
        {searchResults.map(r => (
          <SearchResult
            key={r.getIn(['item', 'nodeUuid']) || `tree:${r.getIn(['item', 'treeUuid'])}`}
            state={state}
            dispatch={dispatch}
            result={r}
          />
        ))}
      </div>
      <div className="flex items-center mt3">
        <div className={'pa2'} onClick={() => setModel(m => m.update('start', l => Math.max(0, l - limit)))}>
          Prev
        </div>
        <div className="gray ph2 w4 tc">
          {Math.ceil(start / limit + 1)} of {Math.ceil(nResults / limit + 1)}
        </div>
        <div className={'pa2'} onClick={() => setModel(m => m.update('start', l => Math.min(nResults - 1, l + limit)))}>
          Next
        </div>
      </div>
    </div>
  );
};

const executeSearch = debounce((dispatch, query) => {
  if (query === '') {
    dispatch(Map({ type: 'CLEAR_SEARCH' }));
  } else {
    dispatch(Map({ type: 'SEARCH_NODES', query }));
  }
}, 250);

export const Analyses = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());
  const ws = state.get('ws');
  const searchResults = state.get('searchResults');
  const count = 10;
  const tree = state.get('trees');
  const [page, setPage] = useState(1);
  const filterType = state.get('filterType');
  const [totalPages, setTotalPages] = useState(List([]));
  const [minPageNumberSet, setMinPageNumberSet] = useState(1);
  const [maxPageNumberSet, setMaxPageNumberSet] = useState(10);
  const [treesList, setTreesList] = useState(List([]));

  useEffect(() => {
    ReactTooltip.rebuild();
    if (!ws) {
      initDispatchedWS(null, dispatch, (err, res) => {
        if (err) {
          dispatch(Map({ type: 'SET_URL', url: '/' }));
        }
      });
    } else {
      dispatch(Map({ type: 'LIST' }));
    }
  }, [ws, dispatch]);

  useEffect(() => {
    setPage(1);
    setTreesList(
      (tree || Map())
        .valueSeq()
        .filter(filterTypeToFilter(filterType))
        .sortBy(t => -1 * t.get('createdAt')),
    );
    let totalPageValue = Math.ceil(
      (tree || Map())
        .valueSeq()
        .filter(filterTypeToFilter(filterType))
        .sortBy(t => -1 * t.get('createdAt')).size / count,
    );
    setTotalPages(Range(1, totalPageValue + 1).toList());
  }, [filterType, tree]);

  const onPageChange = value => {
    if (totalPages.size >= value) {
      setPage(value);
    }
  };

  const onClickPageSet = type => {
    if (type === 'next') {
      if (totalPages.size > maxPageNumberSet) {
        let minPageSet = minPageNumberSet + 10;
        let maxPageSet = maxPageNumberSet + 10;
        setMinPageNumberSet(minPageSet);
        setMaxPageNumberSet(maxPageSet);
      }
    } else {
      if (totalPages.size > minPageNumberSet && minPageNumberSet > 1) {
        let minPageSet = minPageNumberSet - 10;
        let maxPageSet = maxPageNumberSet - 10;
        setMinPageNumberSet(minPageSet);
        setMaxPageNumberSet(maxPageSet);
      }
    }
  };

  return (
    <Page title={'RCAs'} state={state} dispatch={dispatch}>
      <ReactTooltip />
      <Sharing state={state} dispatch={dispatch} />
      <div className="flex items-center mb3">
        <Button
          small
          className={'w5'}
          icon={<Add24 className={'white fill--white'} />}
          text={'RCA Wizard'}
          onClick={() => dispatch(Map({ type: 'SET_URL', url: '/new-analysis/risk-assessment' }))}
        />
        <div
          className="ml2 relative"
          onMouseEnter={() => setModel(model.set('hover', true))}
          onMouseLeave={() => setModel(model.set('hover', false))}
        >
          <Button
            small
            className={'w5 h-100'}
            icon={<ChevronDown20 className={''} />}
            theme={'secondary'}
            text={'Quick Launch RCA'}
          />
          <div
            style={{ top: '2.25rem' }}
            className={classNames('absolute left-0 w-100 z-1 bg-white', {
              dn: !model.get('hover'),
            })}
          >
            <Button
              onClick={() =>
                dispatch(
                  Map({
                    type: 'NEW_TREE',
                    tree: Map({ methodology: 'PROACT' }),
                    events: List(),
                  }),
                )
              }
              icon={<Flow20 />}
              text="PROACT"
            />
            <Button
              icon={<Fish20 />}
              onClick={() =>
                dispatch(
                  Map({
                    type: 'NEW_TREE',
                    tree: Map({ methodology: 'FISHBONE' }),
                    events: List(),
                  }),
                )
              }
              text="Fishbone"
            />
            <Button
              // eslint-disable-next-line
              icon={<Number_524 />}
              onClick={() =>
                dispatch(
                  Map({
                    type: 'NEW_TREE',
                    tree: Map({ methodology: '5WHYS' }),
                    events: List(),
                  }),
                )
              }
              text="Five Whys"
            />
          </div>
        </div>
      </div>
      <div className={'flex justify-between items-center mb4'}>
        <div className="w-40 flex items-center">
          <Input
            value={model.get('omnisearch')}
            onChange={e => {
              const query = e.target.value;
              setModel(m => m.set('omnisearch', query));
              executeSearch(dispatch, query);
            }}
            placeholder="Search all RCAs"
          />
          <div
            onClick={() => {
              dispatch(Map({ type: 'CLEAR_SEARCH' }));
              setModel(m => m.set('omnisearch', ''));
            }}
            className={classNames('pa2 blue dim items-center mt1 pointer', {
              flex: searchResults,
              dn: !searchResults,
            })}
          >
            <Misuse20 />
          </div>
        </div>
        <div className={classNames({ dn: searchResults })}>
          <AnalysesFilters state={state} dispatch={dispatch} />
        </div>
      </div>
      {searchResults ? (
        <SearchResults state={state} dispatch={dispatch} />
      ) : tree && tree.size > 0 ? (
        <>
          <TreeList
            state={state}
            dispatch={dispatch}
            page={page}
            count={count}
            treesList={treesList}
            filterType={filterType}
          />
          {totalPages.size > 0 ? (
            <div className="tc mw8 center mt2">
              <div className="dib overflow-hidden ba br2 b--light-silver">
                <nav className="cf" data-name="pagination-numbers-bordered">
                  <button
                    className="fl dib link dim black f6 f5-ns b pa3 br b--light-silver bg-white"
                    onClick={() => {
                      if (page > 1) {
                        let newPage = page - 1;
                        onPageChange(newPage);
                      }
                    }}
                    title="Previous"
                  >
                    &larr; Previous
                  </button>
                  <button
                    className="fr dib link dim black f6 f5-ns b pa3 bg-white"
                    onClick={() => {
                      if (page !== totalPages) {
                        let newPage = page + 1;
                        onPageChange(newPage);
                      }
                    }}
                    title="Next"
                  >
                    Next &nbsp;&rarr;
                  </button>
                  {totalPages.size >= maxPageNumberSet ? (
                    <>
                      <button
                        className="fl dib link dim black f6 f5-ns b pa3 br b--light-silver bg-white"
                        onClick={() => {
                          onClickPageSet('prev');
                        }}
                        title="Previous"
                      >
                        <PreviousFilled20 />
                      </button>
                      <button
                        className="fr dib link dim black f6 f5-ns b pa3 br bg-white"
                        onClick={() => {
                          onClickPageSet('next');
                        }}
                        title="Next"
                      >
                        <NextFilled20 />
                      </button>
                    </>
                  ) : null}

                  <div className="overflow-hidden center dt tc">
                    {totalPages
                      .filter(i => i >= minPageNumberSet && i <= maxPageNumberSet)
                      .map(x => (
                        <button
                          className="dtc link dim black f6 f5-ns b pa3 br b--light-silver bg-white"
                          onClick={() => onPageChange(x)}
                          title={x}
                          key={x}
                        >
                          {x}
                        </button>
                      ))}
                  </div>
                </nav>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </Page>
  );
};
