import React from 'react';

export const ErrorBanner: React.FC<{ message: string }> = ({ message }) => (
  <div className="flex items-center justify-center pv2 ph4 bg-red white ml-auto mr-auto br1 mv2">
    <svg className="w1" data-icon="info" viewBox="0 0 32 32" style={{ fill: 'currentcolor' }}>
      <path d="M16 0 A16 16 0 0 1 16 32 A16 16 0 0 1 16 0 M19 15 L13 15 L13 26 L19 26 z M16 6 A3 3 0 0 0 16 12 A3 3 0 0 0 16 6"></path>
    </svg>
    <pre>
      <code className="ml3">{message}</code>
    </pre>
  </div>
);
