import React from 'react';
import { TableInstance } from 'react-table';

import { DispatchProp } from '@interfaces';
import { Map } from 'immutable';

type SetInstanceFn<T extends Record<string, unknown>> = (instance: TableInstance<T>) => void;

type DataTableContextValue<T extends Record<string, unknown>> = {
  instance: TableInstance<T> | null;
  setInstance: SetInstanceFn<T>;
  // dispatchable actions
  dispatch: DispatchProp;
  addToTemplateLibrary: (treeUuid: string) => void;
  deleteRCA: (treeUuid: string) => void;
};

export const DataTableContext = React.createContext<DataTableContextValue<any>>({
  instance: null,
  setInstance: () => null,
  dispatch: () => null,
  addToTemplateLibrary: () => null,
  deleteRCA: () => null,
});

export function useDataTableContext<T extends Record<string, unknown>>() {
  const context = React.useContext<DataTableContextValue<T>>(DataTableContext);
  return context;
}

export const DataTableProvider: React.FC<{ dispatch: DispatchProp }> = ({ children, dispatch }) => {
  const [instance, setInstanceFn] = React.useState<TableInstance | null>(null);
  const setInstance = React.useCallback((instance: TableInstance) => setInstanceFn(instance), [setInstanceFn]);
  const addToTemplateLibrary = React.useCallback(
    (treeUuid: string) => dispatch(Map({ type: 'ADD_TO_TEMPLATE_LIBRARY', treeUuid })),
    [dispatch],
  );
  const deleteRCA = React.useCallback(
    (treeUuid: string) => dispatch(Map({ type: 'DELETE_TREE', treeUuid })),
    [dispatch],
  );

  const contextValue = React.useMemo(
    () => ({
      instance,
      setInstance,
      dispatch,
      addToTemplateLibrary,
      deleteRCA,
    }),
    [instance, setInstance, dispatch, addToTemplateLibrary, deleteRCA],
  );

  return <DataTableContext.Provider value={contextValue}>{children}</DataTableContext.Provider>;
};
